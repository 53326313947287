const profile = process.env.APP_PROFILE;

const apiEndpoints = {
    local: "http://localhost:8888/spa",
    staging: "https://api-staging.iheartcaptions.cc/spa",
    production: "https://api.iheartcaptions.cc/spa"
}

const config = {
    turnstileId: '0x4AAAAAAAQ0Ho2rUBY7eAvj',
    appName: 'I ♡ Captions',
    environment: process.env.NODE_ENV,
    tos: 'https://dl.komoni.mx/ilovetranscriptions/tos.html',
    profile: profile,
    apiEndpoint: apiEndpoints[profile] || apiEndpoints.local,
    pusher: {
        appKey: "c6044178a8c7e25db828",
        cluster: "us2"
    }
}

export default config;
