import {Navigate} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";

const withGuest = Component => {
    const WithGuest = function () {
        const user = useSelector((state) => state.identity.user);

        if (user !== null) {
            return <Navigate to="/dashboard" replace={true}/>
        }

        return <Component/>;
    }

    return <WithGuest/>
}

export default withGuest;
