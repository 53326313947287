import React from "react";
import PageLoader from "../components/ui/PageLoader";

export default Component => {
    const WithSuspense = function () {
        return <Component fallback={<PageLoader/>}/>;
    }

    return WithSuspense;
}
