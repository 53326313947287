import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {createLogger} from "redux-logger/src";

// Reducers
import presets from '../reducers/presets/presetsSlice';
import media from '../reducers/media/mediaSlice';
import identity from '../reducers/identity/identitySlice';

const environment = process.env.NODE_ENV;
const middleware = [];

if (environment === 'development') {
    const dontLogThisActions = [
        'media/updatePendingMedia'
    ];
    middleware.push(createLogger({
        collapsed: true,
        duration: true,
        diff: true,
        predicate: (getState, action) => !dontLogThisActions.includes(action.type)
    }));
}

const reducer = combineReducers({
    identity,
    presets,
    media,
});

const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => [
        ...getDefaultMiddleware(),
        ...middleware,
    ]
});

export default store;

