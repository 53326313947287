import React from "react";
import NavBar from "./NavBar";
import SideBar from "./SideBar";

export default function Navigation() {
    return (
        <>
            <SideBar/>
            <NavBar/>
        </>
    );
}
