import React from "react";

export default function Errors(props) {
    const errors = props.errors || [];
    return (
        <div className="mt-1">
            {errors.map((error, key) =>
                <div key={key} className="text-tiny+ text-error">{error}</div>
            )}
        </div>
    )
}
