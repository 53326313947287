import {toast} from "react-toastify";

export default class Notifications {

    static fatal(message) {
        toast(message, {
            autoClose: false,
            type: "error",
            closeButton: false,
            closeOnClick: false,
            draggable: false
        });
    }

    static warning(message) {
        toast(message, {
            type: "warning"
        });
    }

    static component(component) {
        toast(component);
    }

    static success(message) {
        toast(message, {
            type: "success"
        });
    }

    static error(message) {
        toast(message, {
            type: "error"
        });
    }
}
