import {useState} from "react";
import {ArrowPathIcon} from "@heroicons/react/24/outline";

export default function ToolButton(props) {
    const [busy, setBusy] = useState(false);
    const extraClasses = props.extraClasses || '';
    const Icon = props.icon;


    const onClick = async function () {
        if (!props.onClick) {
            return;
        }
        setBusy(true);
        await props.onClick();
        setBusy(false);
    }

    return (
        <button
            className={`btn border flex items-center text-xs+ px-1.5 py-1 border-slate-400 hover:bg-slate-400 hover:text-white ${extraClasses}`}
            onClick={onClick}
        >
            {busy && <ArrowPathIcon className="w-4 h-4 mr-1 animate-spin" />}
            {Icon && !busy && <Icon className="w-4 h-4 mr-1" />}
            {props.label}
        </button>
    )
}
