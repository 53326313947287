import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";

export default function Modal(props) {

    const onClose = () => {
        if (props.static) {
            return;
        }
        props.onClose();
    }

    return (
        <Transition show={props.open} as={Fragment}>
            <Dialog onClose={onClose} static>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out transition-opacity duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/40" aria-hidden="true" style={{zIndex: 100}}/>
                </Transition.Child>

                <div className="fixed inset-0 flex items-center justify-center p-4 backdrop-blur" style={{zIndex: 200}}>

                    <Transition.Child
                        enter="ease-out duration-100 delay-75"
                        enterFrom="scale-90 opacity-0"
                        enterTo="scale-100 opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="relative max-w-lg rounded-lg bg-white text-center dark:bg-navy-700">
                            {props.children}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
