import BaseLayout from "./BaseLayout";
import React from "react";
import {Outlet} from "react-router-dom";
import EmptyLayout from "./EmptyLayout";

const layouts = {
    base: BaseLayout,
    empty: EmptyLayout
}
export default class Layout extends React.Component {
    getLayout() {
        const {name} = this.props;
        if (!layouts[name]) {
            return layouts.base;
        }
        return layouts[name];
    }

    render() {
        const PageLayout = this.getLayout();
        const useChildren = this.props.useChildren === true;
        
        return (
            <PageLayout {...this.props}>
                {useChildren ? this.props.children : <Outlet/>}
            </PageLayout>
        )
    }
}

Layout.defaultProps = {
    name: 'base'
}
