class GtmTracker {
    constructor() {
        this.dataLayer = null;
        this.userId = null;
    }

    setDataLayer(dataLayer) {
        this.dataLayer = dataLayer;
    }

    hasLayer() {
        return this.dataLayer !== null
    }

    setUserId(userId) {
        this.userId = userId;
    }

    push(eventName, properties = {}) {
        if (!this.hasLayer()) {
            return;
        }
        this.dataLayer.push({
            user_id: this.userId,
            event: eventName,
            ...properties
        })
    }
}

export default new GtmTracker();

export const GtmEvents = {
    APP_LOGIN: 'app_login',
    APP_LOGOUT: 'app_logout',
    APP_REGISTER: 'app_register',
    APP_AUTO_LOGIN: 'app_auto_login',
    APP_AUTO_LOGOUT: 'app_auto_logout',

    MEDIA_UPLOADED: 'media_uploaded',
    MEDIA_DELETED: 'media_deleted',

    PRESET_CREATED: 'preset_created',
    PRESET_UPDATED: 'preset_updated',
    PRESET_DELETED: 'preset_deleted',

    CAPTIONS_DOWNLOADED: 'captions_downloaded',
}
