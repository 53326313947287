import * as ChannelService from '@channel.io/channel-web-sdk-loader';

const pluginKey = "64114dcb-1d91-4deb-bdfc-732d93b94fe1";

class ChannelTalkWrapper {
    constructor() {
        this.active = false;
    }
    boot(identity = null) {
        if (!this.active) {
            return;
        }

        const options = {
            pluginKey: pluginKey
        };

        ChannelService.shutdown()

        if (identity !== null) {
            const {user, workspace} = identity;
            options.memberId = user.ulid;
            options.profile = {
                name: user.name,
                email: user.email,
                workspace: workspace.name,
                workspaceId: workspace.ulid,
            }
        }

        setTimeout(() => {
            ChannelService.boot(options);
        }, 1000)
    }
}

const singleton = new ChannelTalkWrapper();

if (process.env.NODE_ENV === 'production') {
    ChannelService.loadScript();
    singleton.active = true;
}

export default singleton
