import Row from "./Row";
import {useState} from "react";
import {DocumentArrowDownIcon, RocketLaunchIcon} from "@heroicons/react/24/outline";
import TranscriptionManager from "../../../classes/managers/transcription";
import ToolButton from "./ToolButton";
import {BeakerIcon, StopIcon} from "@heroicons/react/24/solid";
import Notifications from "../../../classes/utilities/Notifications";
import SpecModal from "./SpecModal";
import DownloadModal from "./DownloadModal";


export default function CaptionEditor(props) {

    const [value, setValue] = useState(props.initialValue || null);
    const [busy, setBusy] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [specModal, setSpecModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);

    if (value.result === null) {
        return null;
    }

    const onEventChange = (index, event) => {
        if (busy) {
            return
        }
        const nextValue = {
            ...value,
            result: {
                ...value.result,
                events: [
                    ...value.result.events
                ]
            }
        };
        nextValue.result.events[index] = event;
        setValue(nextValue);
        setHasChanges(true);
    }

    const onSave = async () => {
        if (busy) {
            return;
        }
        try {
            setBusy(true);
            await TranscriptionManager.update(value);
            Notifications.success('Transcripción guardada');
            setHasChanges(false);
        } catch (error) {
            console.error(error)
            Notifications.error('No se pudo guardar, por favor intenta de nuevo más tarde.');
        } finally {
            setBusy(false);
        }
    }

    const onSpecsApplied = (transcription) => {
        setValue(transcription);
        setSpecModal(false);
        setHasChanges(true);
        Notifications.success('Spec aplicado correctamente. Recuerda que si guardas los cambios, la version anterior se perderá.');
    };

    const onRequestSpec = () => {
        if (busy) {
            return;
        }
        if (hasChanges) {
            alert('Tienes cambios sin guardar, por favor guarda antes de aplicar algún spec.');
            return;
        }
        setSpecModal(true);
    }

    const onRequestDownload = () => {
        if (busy) {
            return;
        }
        if (hasChanges) {
            alert('Tienes cambios sin guardar, por favor guarda antes de descargar el archivo.');
            return;
        }
        setDownloadModal(true);
    }

    return (
        <div className="p-4">
            <SpecModal
                open={specModal}
                ulid={value.ulid}
                onClose={() => setSpecModal(false)}
                onSpec={onSpecsApplied}
            />
            <DownloadModal
                open={downloadModal}
                media={props.media}
                onClose={() => setDownloadModal(false)}
            />
            <div className="border border-b-0 rounded-t-lg border-slate-300">
                <div className="p-2 flex">
                    <div className="flex-1 flex items-center">
                        <StopIcon className={`w-4 h-4 text-slate-200 ${hasChanges ? 'text-red-500' : ''}`}/>
                        <h3 className="text-md ml-1">Editar transcripción</h3>
                    </div>
                    <div className="flex">

                        <ToolButton
                            label="Descargar"
                            icon={DocumentArrowDownIcon}
                            onClick={onRequestDownload}
                        />

                        <ToolButton
                            extraClasses="ml-2"
                            label="Spec"
                            icon={BeakerIcon}
                            onClick={onRequestSpec}
                        />

                        <div className="mx-2 border-r"/>

                        <ToolButton
                            extraClasses="bg-emerald-500 text-white border-0 hover:bg-emerald-700"
                            label="Guardar"
                            icon={RocketLaunchIcon}
                            onClick={onSave}
                        />

                    </div>
                </div>
            </div>

            <div className="p-2 border rounded-b-lg border-slate-300">
                {value.result.events.map((event, key) =>
                    <Row key={key} event={event} onChange={event => onEventChange(key, event)}/>
                )}
            </div>
        </div>
    )
}
