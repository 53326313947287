import FileDownloader from "../utilities/FileDownloader";
import Api from "../api/Api";

const extensions = {
    def: 'txt',
    srt: 'srt',
    log: 'txt',
    scc: 'scc',
    dfxp: 'dfxp',
    vtt: 'vtt',
    sbv: 'sbv',
    docx: 'docx',
}

const binaryTypes = {
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}

export default class SubtitleManager {
    static async download({media, format, preset}) {
        const data = await SubtitleManager.get({media, format, preset});
        const extension = extensions[format] || extensions.def;
        const name = `${media.name}.${extension}`;

        if (window.logSubtitle === true) {
            console.log(data);
            return;
        }

        FileDownloader.local(SubtitleManager.makeBlob(data, format), name);
    }

    static makeBlob(data, format) {

        if (binaryTypes[format]) {
            const decoded = SubtitleManager.decodeBase64(data);
            return new Blob([decoded], {
                type: binaryTypes[format]
            });
        }

        return new Blob([data], {
            type: "text/plain"
        });
    }

    static decodeBase64(data) {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }

    static async get({media, format, preset}) {
        return Api.getSubtitle(media.ulid, format, preset);
    }
}
