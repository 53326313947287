import Api from "../api/Api";

export default class MediaManager {
    static index() {
        return Api.indexMedia();
    }

    static async create(configuration) {

        const media = await Api.storeMedia({
            transcribe_on_upload: configuration.data.transcribeAfterUpload,
            original_name: configuration.data.file.name,
            language: configuration.data.language,
            engine: configuration.data.engine,
            name: configuration.data.name,
            type: configuration.data.type
        });

        if (typeof configuration.onMediaCreated === 'function') {
            configuration.onMediaCreated(media);
        }

        await Api.uploadMedia(media, configuration.data.file, progress => {
            if (typeof configuration.onUploadProgress === 'function') {
                configuration.onUploadProgress({media, progress});
            }
        });

        await Api.verifyMedia(media.ulid);

        if (typeof configuration.onUploadCompleted === 'function') {
            configuration.onUploadCompleted(media);
        }

        return media;
    }

    static delete(ulid) {
        return Api.destroyMedia(ulid);
    }

    static async get(id) {
        const result = await Api.getMedia(id);
        result.media.transcriptions = result.media.transcriptions.map(transcription => {
            transcription.result = JSON.parse(transcription.result);
            return transcription;
        });
        return result
    }
}
