import Api from "../api/Api";

export default class TranscriptionManager {
    static async transcribe(media) {
        return Api.transcribeMedia(media.ulid)
    }

    static async update(transcription) {
        return Api.updateTranscriptionResult(transcription.ulid, JSON.stringify(transcription.result));
    }

    static show(transcription, preset) {
        return Api.showTranscription(transcription.ulid, preset);
    }
}
