export default function toTime(seconds) {
    const date = new Date(null);
    date.setSeconds(seconds || 0);
    const elements = date.toISOString()
        .substr(11, 8)
        .split(':');

    if (elements[0] === "00") {
        elements.shift();
    }

    return elements.join(':');
}
