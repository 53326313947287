import React, {useEffect, useState} from "react";
import Page from "../components/base/Page";
import {useLoaderData, useNavigate} from "react-router-dom";
import MediaManager from "../classes/managers/media";
import formatBytes from "../classes/utilities/formatBytes";
import {getLanguageById} from "../constants/all-language-codes"
import formatTime from "../classes/utilities/formatTime";
import Notifications from "../classes/utilities/Notifications";
import {BookmarkSlashIcon, DocumentTextIcon, MicrophoneIcon} from "@heroicons/react/24/outline";
import {FilmIcon} from "@heroicons/react/24/solid";
import {getEngineNameString} from "../constants/stt-engines";
import CaptionEditor from "../components/modules/caption-editor/CaptionEditor";

export function mediaEditPageLoader({params})  {
    return MediaManager.get(params.id);
}

const icons = {
    "1": MicrophoneIcon,
    "2": FilmIcon,
    "3": DocumentTextIcon,
    default: BookmarkSlashIcon
}

export default function MediaEditPage() {
    const {media} = useLoaderData();
    const transcription = media.transcriptions[0];
    const language = getLanguageById(media.language);
    const navigate = useNavigate();
    const icon = icons[String(media.type)] || icons.default;
    const engine = getEngineNameString(media.engine);

    useEffect(() => {
        if (media.status !== 7) {
            Notifications.error("You cannot edit this media.");
            navigate("/media");
        }
    }, [media]);

    return (
        <Page title={`Edit ${media.name}`} icon={icon}>
            <div className="grid grid-cols-12 lg:gap-6 pt-4">
                <div className="col-span-12 lg:col-span-8">
                    <div className="card">
                        <CaptionEditor media={media} name={media.name} initialValue={transcription}/>
                    </div>
                </div>

                <div className="col-span-12 py-6 p-4 lg:p-0 lg:pt-0 lg:col-span-4">
                    <p className="border-b border-slate-200 pb-2 text-base text-slate-600">
                        Detalles
                    </p>
                    <div className="mt-2">
                        <div className="text-base leading-tight">
                            <div>{media.name} </div>
                            <div className="text-xs mt-1">
                                {engine} - {language.label}
                            </div>
                            <div className="text-xs mt-0.5">
                                {formatTime(media.duration)} - {formatBytes(media.size)}
                            </div>
                        </div>
                        <div className="my-2 h-px bg-slate-200 dark:bg-navy-500"/>
                        <div className="text-slate-400 text-xs+">
                            <div>
                                <strong>Id de soporte:</strong> {media.ulid}
                            </div>
                            <div>
                                <strong>Fecha de creación:</strong> {media.created_at}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}
