import {useEffect, useRef} from "react";
import formatTime from "../../../classes/utilities/formatTime";
import {ChevronRightIcon} from "@heroicons/react/24/solid";

export default function Row(props) {
    const {lines, start, end} = props.event;

    const body = useRef(null);

    const onChange = (text) => {
        const nextEvent = {...props.event}
        nextEvent.lines = text.split("\n");
        props.onChange(nextEvent);
    }

    const fixHeight = () => {
        if (!body.current) {
            return;
        }
        body.current.style.height = "0px";
        const scrollHeight = body.current.scrollHeight;
        body.current.style.height = scrollHeight + "px";
    }

    useEffect(() => {
        window.addEventListener("resize", fixHeight);
        return () => {
            window.removeEventListener("resize", fixHeight);
        }
    }, []);

    useEffect(() => {
        fixHeight();
    }, [body, lines]);

    return (
        <div className="flex mb-2 last:mb-0 text-base items-center">
            <div className="text-slate-400 text-xs flex-col justify-center" style={{width: 55}}>
                <div>{formatTime(start)}</div>
                <div>{formatTime(end)}</div>
            </div>
            <ChevronRightIcon className="w-4 h-4 text-slate-300 m-2"/>
            <div className="w-full flex items-center">
                <textarea
                    className="w-full block resize-none outline-none text-sm font-inter font-light"
                    ref={body}
                    onChange={(e) => onChange(e.target.value)}
                    value={lines.join("\n")}
                />
            </div>
        </div>
    )
}
