import {createSlice} from '@reduxjs/toolkit';
import {listPresets} from "../api/actions";

export const initialState = {
    presets: [],
    loading: false
};

const presetsSlice = createSlice({
    name: 'presets',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(listPresets.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(listPresets.fulfilled, (state, action) => {
            state.loading = false;
            state.presets = action.payload.presets;
        });
        builder.addCase(listPresets.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

// export const {} = presetsSlice.actions;

export default presetsSlice.reducer;
