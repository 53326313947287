const engines =  [
    {
        id: "whisper",
        label: "Pumpkin",
        description: 'Transcripción rápida de alta precisión. Los tiempos no son muy precisos. Duración máxima de 30 minutos',
    },
    {
        id: "azure",
        label: "Lullaby",
        description: 'Transcripción lenta de alta precisión con tiempos precisos.',
    }
]

export default engines;

export function getEngineNameString(engineName) {
    const parts = engineName.split("-");
    const variant = parts[1];

    const engine = engines.find(engine => engine.id === parts[0]);

    if (!engine) {
        return engineName;
    }

    let result = engine.label;

    if (variant && Array.isArray(engine.variants)) {
        const _variant = engine.variants.find(v => v.id === variant);
        if (_variant) {
            result += ` ${_variant.label}`;
        }
    }

    return result;
}
