import React from "react";
import Head from "./Head";
import SubscriptionAlert from "../ui/alerts/SubscriptionAlert";
import DemoAlert from "../ui/alerts/DemoAlert";

export default function Page(props) {
    const containerClass = props.containerClass || 'main-content w-full px-[var(--margin-x)] pb-8';
    const Icon = props.icon;

    return (
        <main className={containerClass}>
            <Head title={props.title}/>

            <SubscriptionAlert/>
            <DemoAlert/>

            {props.title && !props.noTitle &&
                <div className="flex items-start pt-4 transition-all duration-[.25s]">
                    <h1 className="text-xl font-medium text-slate-700 line-clamp-1 flex-1 flex items-center">
                        {Icon && <Icon className="w-6 h-6 mr-2"/>}
                        {props.title}
                    </h1>
                    {props.titleActions &&
                        <div className="ml-2">
                            {props.titleActions}
                        </div>
                    }
                </div>
            }

            <div>
                {props.children}
            </div>
        </main>
    );
}
