import {createSlice} from '@reduxjs/toolkit';
import {createNewMedia, listMedia} from "../api/actions";

export const initialState = {
    media: [],
    pending: [],
    count: 0
};

const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        addPendingMedia: (state, action) => {
            state.pending = [
                ...state.pending,
                {...action.payload, progress: 0, completed: false, error: null, created: false},
            ];
        },
        updatePendingMedia: (state, action) => {
            const key = action.payload.key || 'id';
            const data = action.payload.data;
            const index = state.pending.findIndex(media => media[key] === data[key]);
            if (index >= 0) {
                state.pending[index] = {
                    ...state.pending[index],
                    ...action.payload.data
                };
            }
        },
        removePendingMedia: (state, action) => {
            const key = action.payload.key || 'id';
            const id = action.payload.id;
            const index = state.pending.findIndex(media => media[key] === id);
            if (index >= 0) {
                state.pending.splice(index, 1);
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(createNewMedia.fulfilled, (state, action) => {
            const index = state.pending.findIndex(media => media.id === action.payload.id);
            if (index >= 0) {
                state.pending.splice(index, 1);
            }
        });
        builder.addCase(createNewMedia.rejected, (state, action) => {
            const index = state.pending.findIndex(media => media.tmpId === action.meta.arg.tmpId);
            if (index >= 0) {
                state.pending[index].error = action.error.message;
            }
        });
        builder.addCase(listMedia.fulfilled, (state, action) => {
            state.media = action.payload.media;
            state.count = action.payload.count;
        });
    },
});

export const {addPendingMedia, updatePendingMedia, removePendingMedia} = mediaSlice.actions;

export default mediaSlice.reducer;
