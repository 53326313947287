import Modal from "../../ui/Modal";
import ModalContent from "../../ui/ModalContent";
import Select from "../form/fields/Select";
import React, {useState} from "react";
import InlineLoader from "../../ui/InlineLoader";
import outputFormats from "../../../constants/output-formats";
import SubtitleManager from "../../../classes/managers/subtitle";

export default function DownloadModal(props) {
    const [format, setFormat] = useState(null);
    const [busy, setBusy] = useState(false);

    if (!props.open) {
        return null;
    }

    const onDownload = async () => {
        setBusy(true);
        await SubtitleManager.download({
            media: props.media,
            format
        });
        setBusy(false);
        props.onClose();
    }

    return (
        <Modal {...props}>
            <ModalContent
                onClose={props.onClose}
                closable={!busy}
                title="Selecciona el formato de descarga"
                initialData={[]}
                renderContent={(data) => {
                    return (
                        <div>
                            {busy &&
                                <div>
                                    <InlineLoader/>
                                    <div>Generando archivo</div>
                                </div>
                            }
                            {!busy &&
                                <>
                                    <Select options={outputFormats} value={format} optionLabel="Selecciona formatp" onChange={setFormat}/>
                                </>
                            }
                        </div>
                    )
                }}
                renderFooter={() =>
                    <>
                        <button
                            disabled={!format || busy}
                            onClick={onDownload}
                            className="btn bg-primary/10 font-medium text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25"
                        >
                            Descargar
                        </button>
                    </>
                }
            />
        </Modal>
    )
}
