import {addPendingMedia, updatePendingMedia} from "../media/mediaSlice";
import AsyncThunk from "../../classes/wrappers/AsyncThunk";
import MediaManager from "../../classes/managers/media";
import SubtitleManager from "../../classes/managers/subtitle";
import TranscriptionManager from "../../classes/managers/transcription";
import PresetManager from "../../classes/managers/preset";
import Api from "../../classes/api/Api";

export const listMedia = AsyncThunk.create(
    'api/media/index',
    async () => {
        return MediaManager.index();
    }
);

export const createNewMedia = AsyncThunk.create(
    'api/media/create',
    async (data, {dispatch}) => {

        const onMediaCreated = media => dispatch(updatePendingMedia({
            key: 'tmpId',
            data: {
                ...media,
                tmpId: data.tmpId,
                created: true
            }
        }));

        const onUploadProgress = ({media, progress}) => dispatch(updatePendingMedia({
            data: {
                id: media.id,
                progress: progress,
            }
        }));

        const onUploadCompleted = media => dispatch(updatePendingMedia({
            data: {
                id: media.id,
                completed: true,
            }
        }));

        dispatch(addPendingMedia({tmpId: data.tmpId}));

        return await MediaManager.create({
            data,
            onMediaCreated,
            onUploadProgress,
            onUploadCompleted,
        });
    }
);

export const downloadTranscription = AsyncThunk.create(
    'api/media/transcription/get',
    async (options) => {
        return SubtitleManager.download({
            media: options.media,
            format: options.format,
            preset: options.preset
        });
    }
);

export const transcribeMedia = AsyncThunk.create(
    'api/media/transcription/get',
    async (options) => {
        return TranscriptionManager.transcribe(options.media);
    }
);

export const listPresets = AsyncThunk.create(
    'api/presets/index',
    async (options) => {
        return PresetManager.index();
    }
);