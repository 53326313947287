import Pusher from "pusher-js";
import Echo from "laravel-echo";
import config from "../../config";
import Api from "../api/Api";
import {EventEmitter} from 'events';

window.Pusher = Pusher;

class WebNotifications extends EventEmitter {

    constructor() {
        super();
        this.workspace = null;
        this.actions = {};

        this.echo = new Echo({
            broadcaster: 'pusher',
            key: config.pusher.appKey,
            cluster: config.pusher.cluster,
            //forceTLS: true,
            authorizer: this.authorizer,
        });
    }

    authorizer(channel, options) {
        return {
            authorize: (socketId, callback) => {
                Api.broadcastAuth(socketId, channel.name)
                    .then(response => callback(null, response.data))
                    .catch(error => callback(error));
            }
        }
    }

    subscribe(workspace) {

        if (typeof workspace === 'string' && workspace.length > 0) {
            this.workspace = workspace;
        }

        if (this.workspace === null) {
            console.log('WebNotifications: Skipping registration because workspace is invalid.');
        }

        this.echo.private(`workspace.${workspace}`)
            .listen('SPANotificationEvent', (event) => {
                this.emit(event.payload.notification, event.payload);
            });
    }

    unsubscribe() {
        this.echo.leave(`workspace.${this.workspace}`);
        this.workspace = null;
        this.channel = null;
    }
}

export default new WebNotifications();
