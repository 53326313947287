import Api from "../api/Api";

export default class PresetManager {
    static async index() {
        return Api.indexPreset();
    }

    static find(ulid) {
        return Api.showPreset(ulid)
    }

    static update(ulid, data) {
        return Api.updatePreset(ulid, PresetManager.clean(data))
    }

    static delete(ulid) {
        return Api.deletePreset(ulid);
    }

    static store(data) {
        return Api.storePreset(PresetManager.clean(data));
    }

    static clean(data) {
        return {
            ...data,
            max_event_duration: parseFloat(data.max_event_duration),
            min_event_duration: parseFloat(data.min_event_duration),
            min_event_gap: parseFloat(data.min_event_gap),
            max_line_length: parseInt(data.max_line_length),
            max_lines: parseInt(data.max_lines),
        }
    }
}
