import Modal from "../../ui/Modal";
import ModalContent from "../../ui/ModalContent";
import PresetManager from "../../../classes/managers/preset";
import Select from "../form/fields/Select";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import InlineLoader from "../../ui/InlineLoader";
import TranscriptionManager from "../../../classes/managers/transcription";

export default function SpecModal(props) {
    const [preset, setPreset] = useState(null);
    const [applying, setApplying] = useState(false);

    const loadData = async () => {
        const presets = await PresetManager.index();
        return presets.presets;
    }

    const applySpec = async () => {
        setApplying(true);
        try {
            const transcription = await TranscriptionManager.show(props, preset);
            props.onSpec(transcription);
        } catch (error) {
            console.error(error);
        } finally {
            setApplying(false);
        }
    }

    if (!props.open) {
        return null;
    }

    return (
        <Modal {...props} static={true}>
            <ModalContent
                onClose={props.onClose}
                closable={!applying}
                load={loadData}
                title="Aplicar Spec"
                initialData={[]}
                renderContent={(data) => {
                    const presets = data.map(preset => ({id: preset.ulid, label: preset.name}));
                    return (
                        <div>
                            {applying &&
                                <div>
                                    <InlineLoader/>
                                    <div>Aplicando Spec, por favor espera</div>
                                </div>
                            }
                            {!applying &&
                                <>
                                    <Select options={presets} value={preset} optionLabel="Selecciona Spec" onChange={setPreset}/>
                                    <p className="mt-2 text-left text-xs">
                                        Puedes editar el preset al hacer click en <br/>el botón de abajo.
                                    </p>
                                </>
                            }
                        </div>
                    )
                }}
                renderFooter={() =>
                    <>
                        {!applying &&
                            <Link to="/presets"
                                  className="mr-3 btn font-medium text-slate-700 hover:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-100 dark:hover:bg-navy-300/20 dark:active:bg-navy-300/25">
                                Editar specs
                            </Link>
                        }

                        <button
                            disabled={applying}
                            onClick={applySpec}
                            className="btn bg-primary/10 font-medium text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25"
                        >
                            Aplicar
                        </button>
                    </>
                }
            />
        </Modal>
    )
}
