const isSSR = () => {
    if (typeof window === 'undefined') {
        console.error('ChannelIO APIs are only executable on browser.');
        return true;
    }
    return false;
};
const isSDKLoaded = () => {
    if (!window.ChannelIO) {
        console.error('ChannelIO is not loaded. Please call loadScript() before calling ChannelIO APIs.');
        return false;
    }
    return true;
};
const safeChannelIO = (...args) => {
    if (isSSR() || !isSDKLoaded()) {
        return;
    }
    window.ChannelIO(...args);
};
/**
 * Loads the Channel SDK script into the document.
 * - Browser-only: This function can only be executed in a browser environment.
 * - Idempotent: If the Channel SDK script is already loaded, calling this function has no effect.
 */
export function loadScript() {
    if (isSSR()) {
        return;
    }
    (function () {
        var w = window;
        if (!!w.ChannelIO) {
            return;
        }
        var ch = function () {
            var _a;
            (_a = ch.c) === null || _a === void 0 ? void 0 : _a.call(ch, arguments);
        };
        ch.q = [];
        ch.c = function (args) {
            var _a;
            (_a = ch.q) === null || _a === void 0 ? void 0 : _a.push(args);
        };
        w.ChannelIO = ch;
        function l() {
            if (w.ChannelIOInitialized) {
                return;
            }
            w.ChannelIOInitialized = true;
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
            var x = document.getElementsByTagName('script')[0];
            if (x.parentNode) {
                x.parentNode.insertBefore(s, x);
            }
        }
        if (document.readyState === 'complete') {
            l();
        }
        else {
            w.addEventListener('DOMContentLoaded', l);
            w.addEventListener('load', l);
        }
    })();
}
/**
 * Initializes the SDK, making `Channel button` and features like marketing pop-ups operational.
 *
 * @param {BootOption} option - Configuration options for initializing the SDK.
 * @param {Callback | undefined} callback - Function to be called after booting is complete. On failure, it receives an error object as its first argument and null as the second. On success, it receives null as the first argument and a user object as the second.
 * @see [Boot Glossary](https://developers.channel.io/docs/glossary#boot)
 * @see [Boot Option](https://developers.channel.io/docs/web-boot-option)
 * @see [User Object](https://developers.channel.io/docs/web-user-object)
 */
export function boot(option, callback) {
    safeChannelIO('boot', option, callback);
}
/**
 * Terminates all SDK operations and reinitializes internal data.
 */
export function shutdown() {
    safeChannelIO('shutdown');
}
/**
 * Displays the messenger interface.
 *
 * @see [ChannelIO: showmessenger API](https://developers.channel.io/docs/web-channelio#showmessenger)
 * @see [Messenger Glossary](https://developers.channel.io/docs/glossary#messenger)
 */
export function showMessenger() {
    safeChannelIO('showMessenger');
}
/**
 * Hides the messenger interface.
 *
 * @see [ChannelIO: hidemessenger API](https://developers.channel.io/docs/web-channelio#hidemessenger)
 * @see [Messenger Glossary](https://developers.channel.io/docs/glossary#messenger)
 */
export function hideMessenger() {
    safeChannelIO('hideMessenger');
}
/**
 * Opens a chat interface
 *
 * - Reveals the `messenger` if hidden.
 * - For an undefined `chatId`, a new chat is created. Any provided `message` populates the chat input. The support bot initializes if active.
 * - If a chat with the given `chatId` exists, the chat will open, and the `message` parameter will be disregarded.
 * - If no matching chat exists, the `home` view opens.
 * - Caution: Utilizing this function outside a click event may cause issues in iOS Safari.
 *
 * @param {string | number | undefined} chatId - ID of the chat to reveal.
 * @param {string | undefined} message - Optional text to populate the chat input field.
 * @see https://developers.channel.io/docs/web-channelio#openchat
 */
export function openChat(chatId, message) {
    safeChannelIO('openChat', chatId, message);
}
/**
 * Initiates a chat by triggering a specified support bot.
 *
 * - Reveals the `messenger` if hidden.
 * - Activates the support bot identified by the given `supportBotId`.
 * - No action is taken if `supportBotId` is not specified.
 * - Shows an error page if a support bot with the specified `supportBotId` does not exist.
 * - Populates the chat input with `message` upon support bot completion if provided.
 * - Caution: Utilizing this function outside a click event may cause issues in iOS Safari.
 *
 * @param {string} supportBotId - Identifier of the targeted support bot.
 * @param {string | undefined} message - Optional message to populate the chat input field post support bot action.
 * @see https://developers.channel.io/docs/web-channelio#opensupportbot
 */
export function openSupportBot(supportBotId, message) {
    safeChannelIO('openSupportBot', supportBotId, message);
}
/**
 * Tracks user event.
 *
 * - If the event is new, it gets created.
 * - Data might take from minutes to hours to appear on the `Desk`.
 *
 * @param {string} eventName - Name of the event. Max length is 30 characters.
 * @param {EventProperty | undefined} eventProperty - Additional properties for the event.
 * @see [ChannelIO: track API](https://developers.channel.io/docs/web-channelio#track)
 * @see [Event Glossary](https://developers.channel.io/docs/event)
 */
export function track(eventName, eventProperty) {
    safeChannelIO('track', eventName, eventProperty);
}
/**
 * Registers a callback function that is invoked when the messenger is displayed.
 * @param {Function} callback - The callback function to be executed.
 */
export function onShowMessenger(callback) {
    safeChannelIO('onShowMessenger', callback);
}
/**
 * Registers a callback function that is invoked when the messenger is hidden.
 * @param {Function} callback - The callback function to be executed.
 */
export function onHideMessenger(callback) {
    safeChannelIO('onHideMessenger', callback);
}
/**
 * Register a callback invoked when the count of messages that the user has not yet read.
 * @param {BadgeChangedCallback} callback
 * @see [ChannelIO: onbadgechanged API](https://developers.channel.io/docs/web-channelio#onbadgechanged)
 * @see [Customization](https://developers.channel.io/docs/web-customization)
 */
export function onBadgeChanged(callback) {
    safeChannelIO('onBadgeChanged', callback);
}
/**
 * Registers a callback function that is invoked when a chat is created.
 * @param {Function} callback - The callback function to be executed.
 */
export function onChatCreated(callback) {
    safeChannelIO('onChatCreated', callback);
}
/**
 * Registers a callback function that is invoked when the user updates their profile.
 * @param {FollowUpChangedCallback} callback - The callback function, receiving the `FollowUpProfile` object as an argument.
 * @see https://developers.channel.io/docs/web-channelio#onfollowupchanged
 */
export function onFollowUpChanged(callback) {
    safeChannelIO('onFollowUpChanged', callback);
}
/**
 * Registers a callback function that is invoked when the user clicks on a link.
 * - Supported link types include:
 *   - Link buttons or text in marketing pop-ups
 *   - Link buttons or text sent by a manager in chat
 * @param {UrlClickedCallback} callback - The callback function, receiving the clicked URL as an argument.
 * @see https://developers.channel.io/docs/web-channelio#onurlclicked
 */
export function onUrlClicked(callback) {
    safeChannelIO('onUrlClicked', callback);
}
/**
 * Clears all registered callbacks
 * @see https://developers.channel.io/docs/web-channelio#clearcallbacks
 */
export function clearCallbacks() {
    safeChannelIO('clearCallbacks');
}
/**
 * Updates user information.
 *
 * @param {UpdateUserInfo} userInfo - Object containing user information to be updated.
 * @param {Callback} [callback] - Optional callback function to be executed upon completion.
 * @see https://developers.channel.io/docs/web-channelio#updateuser
 */
export function updateUser(userInfo, callback) {
    safeChannelIO('updateUser', userInfo, callback);
}
/**
 * Adds tags to a user.
 * @param {string[]} tags - Unique tags only. Max 10, always in lowercase.
 * @param {Callback} callback - Called on success or failure.
 * @see https://developers.channel.io/docs/web-channelio#addtags
 */
export function addTags(tags, callback) {
    safeChannelIO('addTags', tags, callback);
}
/**
 * Removes tags from a user.
 * @param {string[]} tags - Ignores non-existent tags. Empty array or null not allowed.
 * @param {Callback} callback - Called on success or failure.
 * @see https://developers.channel.io/docs/web-channelio#removetags
 */
export function removeTags(tags, callback) {
    safeChannelIO('removeTags', tags, callback);
}
/**
 * Sets the current page.
 * - Page can be used instead of canonical URL.
 * @param {string} page - The page to set. Do not pass null or undefined. Use `resetPage` to clear.
 * @see [ChannelIO: setpage API](https://developers.channel.io/docs/web-channelio#setpage)
 * @see [page Glossary](https://developers.channel.io/docs/page)
 * @see [canonical-url Glossary](https://developers.channel.io/docs/canonical-url)
 */
export function setPage(page) {
    safeChannelIO('setPage', page);
}
/**
 * - Reset the page value.
 * - If you use resetPage, a canonical URL is used as the page value.
 * @see [ChannelIO: resetpage API](https://developers.channel.io/docs/web-channelio#resetpage)
 * @see [page Glossary](https://developers.channel.io/docs/page)
 * @see [canonical-url Glossary](https://developers.channel.io/docs/canonical-url)
 */
export function resetPage() {
    safeChannelIO('resetPage');
}
/**
 * Shows the channel button.
 * - No need to call if `hideChannelButtonOnBoot` is false or `hideChannelButton` was not called.
 * @see [ChannelIO: showChannelButton API](https://developers.channel.io/docs/web-channelio#showchannelbutton)
 * @see [Channel Button Glossary](https://developers.channel.io/docs/glossary#channel-button)
 */
export function showChannelButton() {
    safeChannelIO('showChannelButton');
}
/**
 * Hides the channel button.
 * @see [ChannelIO: hidechannelbutton API](https://developers.channel.io/docs/web-channelio#hidechannelbutton)
 * @see [Channel Button Glossary](https://developers.channel.io/docs/glossary#channel-button)
 */
export function hideChannelButton() {
    safeChannelIO('hideChannelButton');
}
/**
 * Sets the appearance theme.
 * @param {Appearance} appearance
 */
export function setAppearance(appearance) {
    safeChannelIO('setAppearance', appearance);
}
