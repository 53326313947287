export default [
    {
        id: "log",
        label: "Plain text (txt)"
    },
    {
        id: "srt",
        label: "SubRip (srt)"
    },
    {
        id: "scc",
        label: "Scenarist (scc)"
    },
    {
        id: "dfxp",
        label: "Netflix Timed Text (dfxp)"
    },
    {
        id: "vtt",
        label: "WebVTT (vtt)"
    },
    {
        id: "sbv",
        label: "YouTube (sbv)"
    },
    {
        id: "docx",
        label: "Word Document (docx)"
    },
]
