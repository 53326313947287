import React from "react";
import Errors from "./Errors";

export default function Select(props) {
    const {label, options, optionLabel, margin, error, value, onChange, hint, disabled} = props;
    const errors = error || [];
    const hasErrors = errors.length > 0;
    const selectClass = ['form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent'];
    const containerClass = ['block'];

    if (margin) {
        containerClass.push('mt-2')
    }
    if (hasErrors) {
        selectClass.push('border-error');
    }
    const handleChange = (event) => {
        if (event.target.value === '_none_') {
            onChange(null);
            return;
        }
        onChange(event.target.value);
    }

    return (
        <>
            <label className={containerClass.join(' ')}>
                {label && <span>{label}</span>}
                <select className={selectClass.join(' ')} onChange={handleChange}
                        value={value || ''} disabled={disabled === true}>
                    <option value="_none_">{optionLabel || `Select ${label}`}</option>
                    {options.map(option =>
                        <option key={option.id} value={option.id}>{option.label}</option>
                    )}
                </select>
                {hasErrors && <Errors errors={errors}/>}
            </label>
            {hint &&
                <div className="text-tiny+ text-slate-400 dark:text-navy-300 mt-1">
                    {hint}
                </div>
            }
        </>
    )
}
