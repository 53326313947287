import React, {useEffect} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import PresetManager from "../classes/managers/preset";
import Page from "../components/base/Page";
import {toast} from "react-toastify";

export async function presetViewLoader({params}) {
    const preset = await PresetManager.find(params.id);
    return {preset};
}

const attributes = [
    "max_event_duration",
    "min_event_duration",
    "min_event_gap",
    "max_line_length",
    "max_lines",
];

const attributeNames = {
    "max_event_duration": "Maximum event duration",
    "min_event_duration": "Minimum event duration",
    "min_event_gap": "Minimum event gap",
    "max_line_length": "Maximum line length",
    "max_lines": "Maximum number of lines",
};

export default function PresetViewPage() {
    const {preset} = useLoaderData();
    const navigate = useNavigate();

    useEffect(() => {
        if (preset.public === 1) {
            toast("You cannot view a public preset.", {
                type: 'error'
            });
            navigate("/presets");
        }
    }, [])

    return (
        <Page title={`Preset ${preset.name}`}>
            <div className="card px-4 py-4 sm:px-5 lg:w-1/2">
                <div>
                    <h2 className="text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
                        Preset details
                    </h2>
                </div>
                <div className="mb-2">
                    <h3 className="text-xl">{preset.name}</h3>
                    <h4 className="text-lg">{preset.description}</h4>
                </div>
                <div
                    className="is-scrollbar-hidden min-w-full overflow-x-auto rounded-lg border border-slate-200 dark:border-navy-500"
                >
                    <table className="w-full text-left">
                        <thead>
                        <tr>
                            <th
                                className="whitespace-nowrap border border-t-0 border-l-0 border-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:border-navy-500 dark:text-navy-100 lg:px-5"
                            >
                                Setting
                            </th>
                            <th
                                className="whitespace-nowrap border border-t-0 border-slate-200 px-3 py-3 font-semibold uppercase text-slate-800 dark:border-navy-500 dark:text-navy-100 lg:px-5"
                            >
                                Value
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {attributes.map(attribute =>
                            <tr key={attribute}>
                                <td
                                    className="whitespace-nowrap border border-l-0 border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5"
                                >
                                    {attributeNames[attribute]}
                                </td>
                                <td
                                    className="whitespace-nowrap border border-slate-200 px-3 py-3 dark:border-navy-500 lg:px-5"
                                >
                                    {preset[attribute]}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Page>
    );
}
