export default class FileDownloader {
    static local(blob, filename) {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('download', filename);
        link.setAttribute('href', url);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
}
