import React, {useEffect} from "react";
import Page from "../components/base/Page";
import InlineLoader from "../components/ui/InlineLoader";
import Auth from "../classes/api/Auth";
import {toast} from "react-toastify";

export default function LogoutPage() {
    useEffect(() => {
        Auth.logout()
            .then(() => toast('See you later!'));
    }, []);

    return (
        <Page title="Logging out" noTitle>
            <div className="py-5 text-center lg:py-6">
                <h2 className="text-lg uppercase">Logging out</h2>
                <p className="text-sm">Please wait</p>
                <InlineLoader/>
            </div>
        </Page>
    );
}
