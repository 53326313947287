import {createAsyncThunk} from "@reduxjs/toolkit";

export default class AsyncThunk {
    static create(typePrefix, payloadCreator) {
        return createAsyncThunk(typePrefix, payloadCreator, {
            serializeError: AsyncThunk.serializeError
        });
    }

    static serializeError(error) {
        const result = {
            message: error.message,
            name: error.name
        };

        if (error.isValidationError) {
            result.isValidationError = true;
            result.fields = error.fields;
        }

        return result;
    }
}
