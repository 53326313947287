import React, {useEffect, useState} from "react";
import {Dialog} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import InlineLoader from "./InlineLoader";

export default function ModalContent(props) {
    const [data, setData] = useState(props.initialData || {});
    const [loading, setLoading] = useState(true);
    const closable = props.closable !== false;

    const loadData = async () => {
        setLoading(true);
        if (typeof props.load === "function") {
            const data = await props.load();
            setData(data);
        }
        setLoading(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div className="py-3 px-3 flex items-center justify-between">
                <Dialog.Title
                    className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">
                    {props.title}
                </Dialog.Title>
                {closable &&
                    <button onClick={props.onClose}
                            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                        <XMarkIcon className="w-4 h-4"/>
                    </button>
                }
            </div>

            {loading &&
                <div className="p-6 pt-2">
                    <InlineLoader/>
                    <div>Cargando, por favor espera</div>
                </div>
            }

            {!loading &&
                <div className="px-3">
                    {props.renderContent && props.renderContent(data)}
                </div>
            }

            {!loading && props.renderFooter &&
                <>
                    <div className="my-3 h-px bg-slate-200 dark:bg-navy-500"/>
                    <div className="pb-3 px-3 flex justify-end">
                        {props.renderFooter(data)}
                    </div>
                </>
            }
        </>
    )
}
